
















































































































import { Component, Vue } from "vue-property-decorator";
import Ladder, { defaultLadders } from "./ladder";
import ILadder from "./ladder.d";
@Component({})
export default class Subway extends Vue {
  public refs!: { [x: string]: any };
  // 每次花销
  private moneySpentEachTime = 0;
  // 次数
  private totalFrequency = 0;
  // 阶梯编辑模板
  private editLadder: ILadder = {
    upperLimit: 0,
    lowerLimit: 0,
    discont: 0,
    name: "",
    id: 0,
  };
  // 阶梯  阶梯1  0-100  阶梯次数  100-150 阶梯3  150-infinity
  private ladders: Ladder[] = [];
  // 结果
  private resultMoney = 0;
  // 处理计算
  private handleCalc() {
    if (!this.moneySpentEachTime || !this.totalFrequency) {
      return;
    }
    const firstLadder = this.ladders[0];
    const starDate = {
      starMoney: firstLadder.lowerLimit, // 当前阶段前面所有money的汇总
      starFrequency: 0, // 当前阶段前面所有次数的汇总
      done: false, // 结束标识
    };
    // use reduce
    const { starMoney: totalMoney } = this.ladders.reduce(
      (total: any, ladder) => {
        const option = {
          ...total,
          moneySpentEachTime: this.moneySpentEachTime,
          totalFrequency: this.totalFrequency,
        };
        return ladder.calc(option);
      },
      starDate
    );
    this.resultMoney = totalMoney;
  }
  // add
  private handleAddladder() {
    let lowerLimit = Number(this.ladders[this.ladders.length - 1].upperLimit);
    lowerLimit = isNaN(lowerLimit) ? 0 : lowerLimit;
    this.ladders.push(
      new Ladder({
        ...this.editLadder,
        lowerLimit,
        upperLimit: "*",
        id: this.ladders.length + 1,
        name: `ladder${this.ladders.length + 1}`,
      })
    );
  }
  // remove
  private handleRemoveladder(index: number) {
    this.ladders.splice(index, 1);
  }
  created() {
    this.ladders = defaultLadders();
    (window as any).sub = this;
  }
}
